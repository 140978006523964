/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('typeface-open-sans-condensed')
require('typeface-merriweather')
require('typeface-fira-sans')
require('firacode')
require('typeface-holtwood-one-sc')
require('typeface-inter')
require('prismjs/themes/prism-tomorrow.css')

exports.shouldUpdateScroll = () => {
  return false
}
