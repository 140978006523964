import { BackgroundColorProperty, ColorProperty, FilterProperty } from 'csstype'

interface IThemeBreakpoints {
  smartphone: string
  tablet: string
  desktop: string
}

interface IThemePalette {
  first: ColorProperty
  second: ColorProperty
  third: ColorProperty
  fourth: ColorProperty
  fifth: ColorProperty
}

interface ITheme {
  background: BackgroundColorProperty
  primary: ColorProperty
  palette: IThemePalette
  filter: FilterProperty
  filterHover: FilterProperty
  filterLens: ColorProperty
  active: ColorProperty
  primaryHover: ColorProperty
  activeIndicator: ColorProperty
  inactiveIndicator: ColorProperty
  breakpoints: {
    min: IThemeBreakpoints
    max: IThemeBreakpoints
  }
}

export const theme: ITheme = {
  palette: {
    first: 'rgb(72, 99, 156)',
    second: 'rgb(76, 76, 157)',
    third: 'rgb(113, 47, 121)',
    fourth: 'rgb(151, 99, 145)',
    fifth: 'rgb(247, 153, 110)',
  },
  filter: '',
  filterHover: '',
  filterLens: '',
  background: '#F8F8F8',
  primary: '#0088CD',
  active: '#00D8FD',
  primaryHover: '#00C6FF',
  activeIndicator: '#ffb74d',
  inactiveIndicator: 'lightgrey',
  breakpoints: {
    min: {
      smartphone: `only screen and (min-width: 600px) and (orientation: portrait)`,
      tablet: `only screen and (min-width: 960px)`,
      desktop: `only screen and (min-width: 1200px)`,
    },
    max: {
      smartphone: `only screen and (max-width: 600px) and (orientation: portrait)`,
      tablet: `only screen and (max-width: 960px)`,
      desktop: `only screen and (max-width: 1200px)`,
    },
  },
}

export const newTheme: Partial<ITheme> = {
  palette: {
    first: 'rgb(79, 93, 117)',
    second: 'rgb(207, 92, 54)',
    third: 'rgb(255, 212, 0)',
    fourth: 'rgb(207, 204, 214)',
    fifth: 'rgb(230, 230, 234)',
  },
  filter: '',
  filterHover: '',
  filterLens: '',
}

export const BLM: Partial<ITheme> = {
  palette: {
    first: 'rgb(32, 32, 32)',
    second: 'rgb(64, 64, 64)',
    third: 'rgb(96, 96, 96)',
    fourth: 'rgb(128, 128, 128)',
    fifth: 'rgb(192, 192, 192)',
  },
  filter: '',
  filterHover: '',
  filterLens: '',
}

export const theme3: Partial<ITheme> = {
  palette: {
    first: 'rgb(39, 76, 119)',
    second: 'rgb(96, 150, 186)',
    third: 'rgb(163, 206, 241)',
    fourth: 'rgb(231, 236, 239)',
    fifth: 'rgb(139, 140, 137)',
  },
  filter: '',
  filterHover: '',
  filterLens: '',
}

export const BREAKPOINTS: { min: IThemeBreakpoints; max: IThemeBreakpoints } = {
  min: {
    smartphone: `only screen and (min-width: 600px) and (orientation: portrait)`,
    tablet: `only screen and (min-width: 960px)`,
    desktop: `only screen and (min-width: 1200px)`,
  },
  max: {
    smartphone: `only screen and (max-width: 600px) and (orientation: portrait)`,
    tablet: `only screen and (max-width: 960px)`,
    desktop: `only screen and (max-width: 1200px)`,
  },
}
